export const setCurrenYear = () => {
    // const authPopup = document.querySelector('.popup')

    const date = new Date()
    const elems = document.querySelectorAll('.curr-year')

    elems.forEach(el => {
        el.textContent = `${date.getFullYear()}`
    })
};

export const getCurrentTime = () => {
    const date = new Date()
    const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const mm = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    const yyyy = date.getFullYear()
    const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
    const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()

    return `${dd}.${mm}.${yyyy}/${hours}:${minutes}`
};

export const showPopup = () => {
    const authPopup = document.querySelector('.popup')
    authPopup.classList.add('active')
    document.body.classList.add('no-scroll')
}

export const hidePopup = () => {
    const authPopup = document.querySelectorAll('.popup')

    hideFaqPopup()

    authPopup.forEach(popup => {
        popup.classList.remove('active')
        document.body.classList.remove('no-scroll')
    })
}

export const showFaqPopup = () => {
    const faqPopup = document.querySelector('.faq')
    faqPopup.classList.add('active')
}

export const hideFaqPopup = () => {
    const faqPopup = document.querySelector('.faq')
    faqPopup.classList.remove('active')
}

export const addFaqPopupData = (email, phone) => {
    const faqLinks = document.querySelectorAll('.faq-list__item')

    faqLinks.forEach(link => {
        link.href = link.href.replace('example@mail.com', email)
        link.href = link.href.replace('+380XXXXXXXXX', phone)
    })
}

export const setZeroToCompanyCardsNumber = () => {
    const nominations = document.querySelectorAll('.nomination')

    nominations.forEach(nomination => {
        const category = nomination.querySelectorAll('.companies__category')

        category.forEach(category => {
            const companies = category.querySelectorAll('.company')
            companies.forEach((company, index) => {
                company.classList.remove('company--add-zero')
                if (index < 9) company.classList.add('company--add-zero')
            })
        })
    })
}

export function setLoader(loader) {
    const nominations = document.querySelector('.nominations')
    const loaderPrevElem = loader.previousElementSibling || null

    nominations.style.pointerEvents = 'none'
    loader.classList.add('active')

    loaderPrevElem && loaderPrevElem.classList.contains('company__overlay') ? loaderPrevElem.classList.add('hidden') : false
}

export function removeLoader(loader) {
    const nominations = document.querySelector('.nominations')
    const loaderPrevElem = loader.previousElementSibling || null

    nominations.style.pointerEvents = 'auto'
    loader.classList.remove('active')

    loaderPrevElem.classList.contains('company__overlay') ? loaderPrevElem.classList.remove('hidden') : false
}

export function clearForm(form) {
    const inputs = form.querySelectorAll('input')
    const submitBtn = form.querySelector('button[type="submit"]')

    inputs.forEach(input => input.value = '')
    submitBtn.disabled = true
}

export function initOtp(otpBlock, submitOtp) {
    // const otpBlocks = document.querySelectorAll('.otp')
    //
    // otpBlocks.forEach((otpBlock) => handleOtpInputs(otpBlock))

    // function handleOtpInputs(otpBlock) {
        const inputs = otpBlock.querySelectorAll('.otp__input')

        inputs.forEach((input, index)=> {
            // Перевіряємо, чи обробники вже додані
            if (!input.dataset.initialized) {
                input.dataset.index = index

                input.addEventListener('paste', handleOtpPaste)
                input.addEventListener('keyup', handleOtpKeyUp)

                // Позначаємо, що обробники вже додані
                input.dataset.initialized = true
            }
            // input.dataset.index = index
            //
            // input.addEventListener('paste', handleOtpPaste)
            // input.addEventListener('keyup', handleOtpKeyUp)
        })

        function handleOtpPaste(event) {
            event.stopPropagation()

            const data = event.clipboardData.getData('text')
            const value = data.split('')

            if (data.length === inputs.length) {
                inputs.forEach((input, index) => input.value = value[index])
                submitOtp(event.target)
            }
        }

        function handleOtpKeyUp(event) {
            event.stopPropagation()

            const input = event.target
            let value = input.value

            input.value = ''
            input.value = value ? value[0] : ''

            let fieldIndex = Number(input.dataset.index)

            if (value.length > 0 && fieldIndex < inputs.length -1) {
                input.nextElementSibling.focus()
            }

            if (event.key === 'Backspace' && fieldIndex > 0) {
                input.previousElementSibling.focus()
            }

            if (fieldIndex === inputs.length - 1) {
                const targetInputs = event.target.parentNode.querySelectorAll('.otp__input')
                const filledInputsQuantity = [...targetInputs].filter(input => input.value.length > 0).length

                if (filledInputsQuantity === 6) {
                    submitOtp(event.target)
                }
            }
        }
    // }
}

export function getEnteredOtpCode(targetInput) {
    const otpBlock = targetInput.closest('.popup__block')
    const otpInputs = otpBlock.querySelectorAll('.otp__input')
    let otpCode = ''

    otpInputs.forEach(input => {
        otpCode += input.value
        input.disabled = true
        input.classList.add('disabled')
    })

    return otpCode
}

export function cleanOtp(targetInput) {
    const otpBlock = targetInput.closest('.popup__block')
    const otpInputs = otpBlock.querySelectorAll('.otp__input')

    otpInputs.forEach(input => {
        input.disabled = false
        input.classList.remove('disabled')
        input.value = ''
    })
}

export function isTokenAlive(accessToken) {
    // Function to decode base64 url
    function base64UrlDecode(str) {
        // Replace URL-safe characters with standard base64 characters
        str = str.replace(/-/g, '+').replace(/_/g, '/');
        // Add padding if necessary
        while (str.length % 4) {
            str += '=';
        }
        return atob(str);
    }

    // Function to get JWT expiration date
    function getJwtExpiration(jwt) {
        if (!jwt) {
            return undefined
        }

        // Split JWT into its three parts
        const parts = jwt.split('.');
        if (parts.length !== 3) {
            throw new Error('Invalid JWT format');
        }

        // Decode the payload
        const payload = base64UrlDecode(parts[1]);
        const payloadObj = JSON.parse(payload);

        // Get expiration time
        if (payloadObj.exp) {
            return new Date(payloadObj.exp * 1000); //Expiration Date
        } else {
            throw new Error('No expiration time found in JWT');
        }
    }

    // Example JWT (Replace with your JWT token)
    try {
        const expirationDate = getJwtExpiration(accessToken)
        const expirationTime = expirationDate ? expirationDate.getTime() : 0;
        const currentTime = new Date().getTime()
        const isAlive = expirationTime > currentTime
        console.log('Is JWT Alive:', expirationTime > currentTime);
        return isAlive;
    } catch (error) {
        console.error(error.message);
    }
}