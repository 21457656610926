import {smoothScroll} from './helpers/smooth-scroll.js';
import {setCurrenYear} from './helpers/service.js';
import {Validation} from "./modules/Validation";
import {Nominations} from "./modules/Nominations";

function main() {
  smoothScroll();

  Validation();
  Nominations();

  setCurrenYear();
}

main();

// if (document.documentElement.clientWidth < 480) {
//   window.addEventListener('scroll',
//     function () {
//       return setTimeout(main, 1000);
//     }, {
//       once: true
//     });
// } else {
//   main();
// }