export const smoothScroll = () => {
    const links = document.querySelectorAll('[href^="#"]')

    links.forEach(link => link.addEventListener('click', handleLink))

    function handleLink(event) {
        event.preventDefault()

        const { target } = event
        const link = target.closest('[href^="#"]') || target
        const elem = document.querySelector(link.getAttribute('href'))

        elem.scrollIntoView({
            behavior: "smooth",
        })
    }
};
