import { messages } from "../helpers/data";
import {
    showPopup,
    hidePopup,
    setZeroToCompanyCardsNumber,
    setLoader,
    removeLoader,
    clearForm,
    initOtp,
    getEnteredOtpCode,
    cleanOtp,
    isTokenAlive, showFaqPopup, hideFaqPopup, addFaqPopupData
} from "../helpers/service";
import {
    regNewUser,
    loginUser,
    getUsersVotes,
    createVote,
    getStat,
    obtainToken,
    getPhoneVerifyStatus,
    sendPhoneVerifyCode,
    verifyPhoneNumber, postTelegramReport,
} from "../helpers/requests";

export const Nominations = () => {
    const headerButton= document.querySelector('.header__btn')
    const logOutBtn = document.querySelector('.header__btn--logout')

    const popup = document.querySelector('.popup')
    const popupBlocks = document.querySelectorAll('.popup__block')
    const regBlock = popup.querySelector('.popup__block--reg')
    const loginBlock = popup.querySelector('.popup__block--login')
    const warningBlock = popup.querySelector('.popup__block--warning')
    const warningTitle = warningBlock.querySelector('.popup__title')
    const warningMsg = warningBlock.querySelector('.popup__message')
    const closePopupButton = document.querySelectorAll('.popup__close')
    const loader = popup.querySelector('.loader')

    const formReg = popup.querySelector('.popup__form--reg')
    const formLogin = popup.querySelector('.popup__form--login')
    const formSwitchers = popup.querySelectorAll('.auth-switcher__btn')
    const regInputName = document.querySelector('.popup__form--reg input[name="name"]')
    const regInputEmail = document.querySelector('.popup__form--reg input[name="email"]')
    const regInputPhone = document.querySelector('.popup__form--reg input[name="phone"]')
    // const loginInputName = document.querySelector('.popup__form--login input[name="name"]')
    // const loginInputEmail = document.querySelector('.popup__form--login input[name="email"]')

    const otpEmailBlock = document.querySelector('.popup__block--otp_email')
    const otpPhoneBlock = document.querySelector('.popup__block--otp_phone')

    const nominations = document.querySelector('.nominations')

    const faqButton = document.querySelectorAll('.popup__faq')
    const faqCloseButton = document.querySelector('.faq__close')

    // Functions for rendering
    // setZeroToCompanyCardsNumber() //added class for indication first 9 company cards and add zero to card's number by CSS

    // On load
    document.addEventListener('DOMContentLoaded', async () => {
        const mainLoader = document.querySelector('.loader--main')
        setLoader(mainLoader)

        const currentToken = localStorage.getItem('auth')
        const isTokenExpired = !isTokenAlive(currentToken)

        if (currentToken && !isTokenExpired) {
            const usersVotes = await getUsersVotes(currentToken) || null

            setUserNameInButton()
            updateVotes(usersVotes)
        } else {
            deleteLoginData(true)
            removeCompanyFromStorage()
        }

        await updatePercents()
        removeLoader(mainLoader)
    })

    // hide logout btn
    document.addEventListener('click', hideLogOut)

    // Call popup
    headerButton.addEventListener('click', async () => {
        const isLogin = localStorage.getItem('auth')
        !isLogin ? showPopup() : logOutBtn.classList.add('active')
    })

    // Call FAQ popup
    faqButton.forEach(btn => btn.addEventListener('click', () => {
        const userRegistrationEmail = localStorage.getItem('userRegistrationEmail');
        const userRegistrationPhone = localStorage.getItem('userRegistrationPhone')
        const userName = localStorage.getItem('userName');
        const emailCodeSending = localStorage.getItem('emailCodeSending');

        const userFeedbackEmail = userName
            || userRegistrationEmail
            || emailCodeSending
            || '_______________';

        const userFeedbackPhone = userRegistrationPhone || '_______________'

        showFaqPopup()
        addFaqPopupData(userFeedbackEmail, userFeedbackPhone)
    }))

    // LogOut
    logOutBtn.addEventListener('click', logOut)

    // Close popup
    closePopupButton.forEach(closeBtn => closeBtn.addEventListener('click', () => {
        hidePopup()
        setTimeout(() => displayPopupScreen(regBlock), 500)
    }))

    // Close FAQ popup
    faqCloseButton.addEventListener('click', () => {
        hideFaqPopup()
    })

    // Click inside nominations
    nominations.addEventListener('click', handleNominationsClick)

    // Submit form
    formSwitchers.forEach(switcher => {
        switcher.addEventListener('click', switchForm)
    })

    formReg.addEventListener('submit', registration)
    formLogin.addEventListener('submit', logIn)

    async function handleNominationsClick(event) {
        const { target } = event
        const isCategoryBtn  = target.classList.contains('categories__button')
        const isVoteButton = target.classList.contains('company__button')

        isCategoryBtn ? switchCategory(target) : false
        isVoteButton ? await leaveVote(target) : false
    }

    function switchCategory(target) {
        const categoryName = target.dataset.category
        const categoryTabs = target.closest('.categories')
        const companies = categoryTabs.nextElementSibling
        const categoryButtons = categoryTabs.querySelectorAll('.categories__button')
        const companyCategories = companies.querySelectorAll('.companies__category')

        categoryButtons.forEach(btn => btn.classList.remove('active'))

        companyCategories.forEach(category => {
            category.classList.remove('active')

            if (category.dataset.category === categoryName) {
                category.classList.add('active')
            }
        })

        target.classList.add('active')
    }

    function switchForm() {
        const allForms = document.querySelectorAll('.popup__block--auth')
        const activeForm = [...allForms].filter(form => form.classList.contains('active'))[0]
        const hiddenForm = [...allForms].filter(form => !form.classList.contains('active'))[0]

        activeForm.classList.remove('active')
        hiddenForm.classList.add('active')
    }

    async function leaveVote(target) {
        const { verificationFailed, tokenIsNotAlive, unexpectedError } = messages.errors.phone
        const { verificationSuccess } = messages.notifications.phone
        const { isExist } = messages.errors.vote

        const cardLoader = target.parentElement.parentElement.querySelector('.loader--card')

        const currentToken = localStorage.getItem('auth')
        const isTokenExpired = !isTokenAlive(currentToken)
        const phoneVerifyStatus = !isTokenExpired ? await getPhoneVerifyStatus(currentToken) : false

        if (phoneVerifyStatus && phoneVerifyStatus.code === 991) {
            const currTime = new Date().getTime()
            const prevCodeSending = Number(localStorage.getItem('phoneCodeSendingTime'))
            const sendingDelay = 900000
            // const sendingDelay = 30000
            const isDelayExpired = currTime >= (prevCodeSending + sendingDelay) || null

            displayPopupScreen(otpPhoneBlock)
            showPopup()

            if (!prevCodeSending || isDelayExpired) {
                const { status, failedStatus } = await sendPhoneVerifyCode(currentToken)

                if (failedStatus) {
                    removeLoader(loader)
                    displayWarning(unexpectedError.title, unexpectedError.text)
                    postTelegramReport([{name: 'Error type', desc: `Send Phone Verify Code / Unexpected error / Code: ${failedStatus}`}])
                    setTimeout(() => {
                        popup.classList.contains('active')
                            ? hidePopup()
                            : false
                        setTimeout(() => displayPopupScreen(otpPhoneBlock), 500)
                    }, 2000)

                    return false
                }

                status === 'ok'
                    ? localStorage.setItem('phoneCodeSendingTime', `${currTime}`)
                    : false
            }

            initOtp(otpPhoneBlock, async function (target){
                setLoader(loader)

                const currentToken = localStorage.getItem('auth')
                const isTokenExpired = !isTokenAlive(currentToken)
                const userOtp = getEnteredOtpCode(target)
                const resStatus = await verifyPhoneNumber(currentToken, userOtp)

                if (resStatus === 200) {
                    removeLoader(loader)
                    displayWarning(verificationSuccess.title, verificationSuccess.text)
                    setTimeout(() => {
                        popup.classList.contains('active')
                            ? hidePopup()
                            : false
                        setTimeout(() => displayPopupScreen(regBlock), 500)
                        cleanOtp(target)
                    }, 2000)

                    return false
                }

                if (resStatus === 401 && isTokenExpired) {
                    removeLoader(loader)
                    displayWarning(tokenIsNotAlive.title, tokenIsNotAlive.text)

                    deleteLoginData()
                    removeCompanyFromStorage()
                    resetLoginButton()

                    setTimeout(() => {
                        displayPopupScreen(loginBlock)
                        cleanOtp(target)
                    }, 2000)

                    return false
                }

                removeLoader(loader)
                displayWarning(verificationFailed.title, verificationFailed.text)
                setTimeout(() => {
                    displayPopupScreen(otpPhoneBlock)
                    cleanOtp(target)
                }, 2000)

                return false
            })

            return false
        }

        const nomination = target.closest('.nomination').dataset.nomination
        const niche = target.closest('.companies__category').dataset.category
        const vote = target.closest('.company').dataset.company

        setCompanyToStorage({
            nomination: nomination,
            niche: niche,
            vote: vote,
        })

        if (!isTokenExpired) {
            setLoader(cardLoader)

            const chosenCompany = getCompanyFromStorage()
            const usersVotes = await getUsersVotes(currentToken)
            const { code, failedStatus } = await createVote(chosenCompany, currentToken)

            if (failedStatus) {
                removeLoader(cardLoader)
                showPopup()
                displayWarning(unexpectedError.title, unexpectedError.text)
                postTelegramReport([{name: 'Error type', desc: `Create Vote / Unexpected error / Code: ${failedStatus}`}])
                setTimeout(() => {
                    popup.classList.contains('active')
                        ? hidePopup()
                        : false
                    setTimeout(() => displayPopupScreen(loginBlock), 500)
                }, 2000)

                return false
            }

            if (code === 400) {
                showPopup()
                displayWarning(isExist.title, isExist.text)

                setTimeout(() => {
                    popup.classList.contains('active')
                        ? hidePopup()
                        : false
                }, 2000)

                removeLoader(cardLoader)
                return false
            }

            updateVotes([...usersVotes, chosenCompany])
            await updatePercents()
            removeLoader(cardLoader)
        } else {
            deleteLoginData()
            removeCompanyFromStorage()
            resetLoginButton()

            showPopup()
            displayWarning(tokenIsNotAlive.title, tokenIsNotAlive.text)
            setTimeout(() => {
                displayPopupScreen(loginBlock)
            }, 2000)
        }
    }

    async function registration(event) {
        event.preventDefault()

        const { isExistEmail, isExistPhone, unexpectedError } = messages.errors.registration
        const { success } = messages.notifications.registration

        const loader = popup.querySelector('.loader')
        setLoader(loader)

        const name = regInputName.value.trim().toLowerCase()
        const email = regInputEmail.value.trim().toLowerCase()
        const phone = regInputPhone.value.trim().toLowerCase()

        localStorage.setItem('userRegistrationEmail', email)
        localStorage.setItem('userRegistrationPhone', phone)

        const res = await regNewUser({
            name: name,
            email: email,
            phoneNumber: phone
        })

        if (res.errorMessage) {
            removeLoader(loader)

            if (res.errorMessage === 'Email is already in use') {
                displayWarning(isExistEmail.title, isExistEmail.text)
            }

            if (res.errorMessage === 'Phone number is already in use') {
                displayWarning(isExistPhone.title, isExistPhone.text)
            }

            setTimeout(() => {
                // popup.classList.contains('active')
                //     ? hidePopup()
                //     : false
                displayPopupScreen(regBlock)
                // setTimeout(() => displayPopupScreen(regBlock), 500)
                // clearForm(formReg)
            }, 2000)

            return false
        }

        if (res.phoneNumber) {
            removeLoader(loader)
            displayWarning(success.title, success.text)
            setTimeout(() => {
                displayPopupScreen(loginBlock)
                clearForm(formReg)
            }, 2000)

            return false
        }

        if (res.failedStatus) {
            removeLoader(loader)
            displayWarning(unexpectedError.title, unexpectedError.text)
            postTelegramReport([{name: 'Error type', desc: `Registration / Unexpected error / Code: ${res.failedStatus}`}])
            setTimeout(() => {
                displayPopupScreen(regBlock)
            }, 2000)

            return false
        }

        removeLoader(loader)
        displayWarning(unexpectedError.title, unexpectedError.text)
        setTimeout(() => {
            // popup.classList.contains('active')
            //     ? hidePopup()
            //     : false
            displayPopupScreen(regBlock)
            // setTimeout(() => displayPopupScreen(regBlock), 500)
            // clearForm(formReg)
        }, 2000)
    }

    async function logIn(event) {
        event.preventDefault()

        const { IsNotExist, incorrectOtp, unexpectedError} = messages.errors.login
        const { success } = messages.notifications.login

        const loader = popup.querySelector('.loader')
        setLoader(loader)

        const loginInputEmail = document.querySelector('.popup__form--login input[name="email"]')
        const email = loginInputEmail.value.trim().toLowerCase()

        const currTime = new Date().getTime()
        const prevCodeSending = Number(localStorage.getItem('emailCodeSendingTime'))
        const isCodeSentToEmail = email === localStorage.getItem('emailCodeSending')
        const sendingDelay = 900000
        // const sendingDelay = 30000
        const isDelayExpired = currTime >= (prevCodeSending + sendingDelay) || null

        let statusCode = 200
        let statusCodeFailed = null

        if (!prevCodeSending || isDelayExpired || !isCodeSentToEmail) {
            const { statusCodeValue, statusCodeError } = await loginUser(email)

            statusCode = statusCodeValue
            statusCodeFailed = statusCodeError

            if (statusCodeValue === 200) {
                localStorage.setItem('emailCodeSending', `${email}`)
                localStorage.setItem('emailCodeSendingTime', `${currTime}`)
            }
        }

        // const { statusCodeValue } = await loginUser(email)

        if (statusCode === 401) {
            removeLoader(loader)
            displayWarning(IsNotExist.title, IsNotExist.text)
            setTimeout(() => {
                displayPopupScreen(regBlock)
                clearForm(formLogin)
            }, 2000)

            return false
        }

        if (statusCode === 200) {
            removeLoader(loader)
            displayPopupScreen(otpEmailBlock)

            initOtp(otpEmailBlock, async function (target){
                setLoader(loader)

                const loginInputEmail = document.querySelector('.popup__form--login input[name="email"]')
                const email = loginInputEmail.value.trim().toLowerCase()

                const userOtp = getEnteredOtpCode(target)

                const { access_token, refresh_token, failedCode } = await obtainToken(email, userOtp)

                if (failedCode) {
                    removeLoader(loader)
                    displayWarning(unexpectedError.title, unexpectedError.text)
                    postTelegramReport([{name: 'Error type', desc: `Obtain Token / Unexpected error / Code: ${failedCode}`}])
                    setTimeout(() => {
                        displayPopupScreen(loginBlock)
                    }, 2000)

                    return false
                }

                if (!access_token) {
                    removeLoader(loader)
                    displayWarning(incorrectOtp.title, incorrectOtp.text)
                    setTimeout(() => {
                        displayPopupScreen(otpEmailBlock)
                        cleanOtp(target)
                    }, 2000)

                    return false
                }

                removeLoader(loader)
                displayWarning(success.title, success.text)
                setTimeout(() => {
                    popup.classList.contains('active')
                        ? hidePopup()
                        : false
                    setTimeout(() => displayPopupScreen(regBlock), 500)
                    cleanOtp(target)
                }, 2000)

                setLoginData(access_token, refresh_token, email)
                setUserNameInButton(email)

                const usersVotes = await getUsersVotes(access_token)
                updateVotes(usersVotes)

                return false
            })
        }

        if (statusCodeFailed) {
            removeLoader(loader)
            displayWarning(unexpectedError.title, unexpectedError.text)
            postTelegramReport([{name: 'Error type', desc: `Log in / Unexpected error / Code: ${statusCodeFailed}`}])
            setTimeout(() => {
                displayPopupScreen(loginBlock)
            }, 2000)

            return false
        }
    }

    function logOut() {
        const mainLoader = document.querySelector('.loader--main')
        setLoader(mainLoader)

        deleteLoginData()
        removeCompanyFromStorage()

        resetVotes()
        resetLoginButton()

        displayPopupScreen(regBlock)

        setTimeout(() => removeLoader(mainLoader), 1000)
    }

    function hideLogOut(event) {
        const {target} = event
        const isLogoutBtn = target.classList.contains('header__btn--logout')
        const isLogInBtn = target.classList.contains('header__btn')
        const isLogOutBtnActive  = logOutBtn.classList.contains('active')

        if (isLogOutBtnActive && !isLogoutBtn && !isLogInBtn) {
            logOutBtn.classList.remove('active')
        }
    }

    function updateVotes(votes) {
        if (!votes) {
            deleteLoginData()
            removeCompanyFromStorage()
            resetLoginButton()
            return false
        }

        votes.forEach(voteObj => {
            const nomination = voteObj.nomination
            const niche = voteObj.niche
            const company = voteObj.vote || undefined

            const nominationBlock = document.querySelector(`[data-nomination="${nomination}"]`)
            const nicheBlock = nominationBlock.querySelector(`.companies__category[data-category="${niche}"]`)
            const companyBlock = company && nicheBlock.querySelector(`[data-company="${company}"]`)

            nicheBlock.classList.add('disabled')
            company && companyBlock.classList.add('voted')
        })
    }

    async function updatePercents() {
        const stat = await getStat()

        if (!stat) {
            deleteLoginData()
            removeCompanyFromStorage()
            resetLoginButton()
            return false
        }

        stat.forEach(statItem => {
            const nominationWrapper = document.querySelector(`[data-nomination="${statItem.nomination}"]`)

            if (nominationWrapper) {
                const companiesWrapper = nominationWrapper.querySelector('.companies')
                const nicheWrapper = companiesWrapper.querySelector(`[data-category="${statItem.niche}"]`)

                statItem['results'].forEach(results => {
                    const companyWrapper = nicheWrapper ? nicheWrapper.querySelector(`[data-company="${results['company']}"]`) : null
                    const votesEl = companyWrapper ? companyWrapper.querySelector('.company__votes') : null

                    votesEl ? votesEl.textContent = `${results['percentage'].toFixed(2)}` : false
                })
            }
        })

        putCompaniesInOrder()
    }

    function putCompaniesInOrder() {
        const categories = document.querySelectorAll('.companies__category')

        categories.forEach(category => {
            const companies = category.querySelectorAll('.company')

            companies.forEach(company => company.classList.add('company--new-dir'))

            const mappedCompanies = [...companies].map((company, index) => {
                const votes = Math.round(company.querySelector('.company__votes').textContent.split('%')[0] * 100)
                return {
                    index: index,
                    votes: votes
                }
            })

            mappedCompanies.sort((a, b) => {
                if (a.votes < b.votes) return 1
                if (a.votes > b.votes) return -1
                return 0
            })

            mappedCompanies.forEach((obj, i) => {
                companies[obj.index].style.order = i.toString()
                companies[obj.index].querySelector('.company__body').dataset.order = (Number(i) + 1).toString()
            })
        })
    }

    function resetVotes() {
        const nicheBlocks = document.querySelectorAll('.companies__category')
        const companyBlocks = document.querySelectorAll('.company')

        nicheBlocks.forEach(block => block.classList.remove('disabled'))
        companyBlocks.forEach(block => block.classList.remove('voted'))
    }

    function displayWarning(title, message) {
        warningTitle.textContent = title
        warningMsg.textContent = message
        popupBlocks.forEach(block => block.classList.remove('active'))
        warningBlock.classList.add('active')
    }

    function displayPopupScreen(popupBlock) {
        popupBlocks.forEach(block => block.classList.remove('active'))
        popupBlock.classList.add('active')
        warningBlock.classList.remove('active')
    }

    function setUserNameInButton() {
        const name = localStorage.getItem('userName')
        name ? headerButton.textContent = name : false
    }

    function resetLoginButton() {
        logOutBtn.classList.remove('active')
        headerButton.textContent = headerButton.dataset.default
    }

    function setLoginData(accessToken, refreshToken, userName) {
        localStorage.setItem('auth', accessToken)
        localStorage.setItem('refresh', refreshToken)
        localStorage.setItem('userName', userName)
    }

    function deleteLoginData(isOnload) {
        localStorage.removeItem('auth')
        localStorage.removeItem('refresh')
        localStorage.removeItem('userName')
        localStorage.removeItem('phoneCodeSendingTime')

        !isOnload ? localStorage.removeItem('emailCodeSendingTime') : false
    }

    function setCompanyToStorage(data) {
        sessionStorage.setItem('chosenCompany', JSON.stringify(data))
    }

    function getCompanyFromStorage() {
        return JSON.parse(sessionStorage.getItem('chosenCompany')) || null
    }

    function removeCompanyFromStorage() {
        const isCompany = getCompanyFromStorage()
        isCompany ? sessionStorage.removeItem('chosenCompany') : false
    }
}