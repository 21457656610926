export const Validation = () => {
    const regForm = document.querySelector(".popup__form--reg");
    const loginForm = document.querySelector(".popup__form--login");

    document.addEventListener('DOMContentLoaded', () => {
        initFormValidation(regForm, 'registration')
        initFormValidation(loginForm, 'login')
    })

    function initFormValidation(form, type) {
        const inputName = form.querySelector('input[name="name"]')
        const inputEmail = form.querySelector('input[name="email"]')
        const inputPhone = form.querySelector('input[name="phone"]')
        const formButton = form.querySelector('.popup__block--auth .popup__button')

        let isEmailBlur = false
        let isPhoneBlur = false

        // validation events
        inputName && inputName.addEventListener('input', () => {
            const isNameValid = validateName()
            validateForm()

            isNameValid ? hideError(inputName) : showError(inputName)
        })

        inputEmail && inputEmail.addEventListener('blur', () => {
            const isEmailValid = validateEmail()

            isEmailBlur  = true

            isEmailValid ? hideError(inputEmail) : showError(inputEmail)
        })

        inputEmail && inputEmail.addEventListener('input', () => {
            const isEmailValid = validateEmail()
            validateForm()

            if (isEmailBlur) {
                isEmailValid ? hideError(inputEmail) : showError(inputEmail)
            }
        })

        inputPhone && inputPhone.addEventListener('input', (e) => {
            inputPhone.value = inputPhone.value.replace(/[^0-9+]/g, '')

            const isPhoneValid = validatePhone()
            validateForm()

            if (isPhoneBlur) {
                isPhoneValid ? hideError(inputPhone) : showError(inputPhone)
            }
        })

        inputPhone && inputPhone.addEventListener('blur', () => {
            const isPhoneValid = validatePhone()

            isPhoneBlur  = true

            isPhoneValid ? hideError(inputPhone) : showError(inputPhone)
        })


        // Functions
        function validateForm() {
            const isNameValid = type === 'registration' ? validateName() : true
            const isEmailValid = validateEmail()
            const isPhoneValid = type === 'registration' ? validatePhone() : true

            if (isNameValid && isEmailValid && isPhoneValid) {
                formButton.removeAttribute('disabled')
            } else {
                formButton.setAttribute('disabled', '')
            }
        }

        function validateName() {
            return inputName.value.trim().length > 0;
        }

        function validateEmail() {
            const emailValidRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return inputEmail.value.trim().match(emailValidRegex);
        }

        function validatePhone() {
            const phoneValidRegExp = /^\+380\d{9}$/
            return inputPhone.value.trim().match(phoneValidRegExp);
        }

        function showError(input) {
            const errorEl = input.nextElementSibling

            input.classList.add('popup__input--error')
            errorEl.classList.add('active')
        }

        function hideError(input) {
            const errorEl = input.nextElementSibling

            input.classList.remove('popup__input--error')
            errorEl.classList.remove('active')
        }
    }
}

