export const messages = {
    errors: {
        registration: {
            isExistEmail: {
                title: 'Користувач з такою електронною поштою вже існує.',
                text: 'Кожен акаунт повинен мати унікальну електронну пошту та номер телефону, яких ще немає в системі.',
            },
            isExistPhone: {
                title: 'Користувач з таким номером телефону вже існує!',
                text: 'Кожен акаунт повинен мати унікальну електронну пошту та номер телефону, яких ще немає в системі.',
            },
            unexpectedError: {
                title: 'Щось пішло не так!',
                text: 'Будь ласка спробуйте пізніше',
            },
        },
        login: {
            IsNotExist: {
                title: 'Такого аккаунту не існує!',
                text: 'Будь ласка пройдіть реєстрацію',
            },
            incorrectOtp: {
                title: 'Невірний код!',
                text: 'Будь ласка спробуйте ще раз',
            },
            unexpectedError: {
                title: 'Щось пішло не так!',
                text: 'Будь ласка спробуйте пізніше',
            },
        },
        phone: {
            verificationFailed: {
                title: 'Невірний код!',
                text: 'Будь ласка спробуйте ще раз',
            },
            tokenIsNotAlive: {
                title: 'Закінчився час сесії!',
                text: 'Будь ласка виконайте вхід ще раз',
            },
            unexpectedError: {
                title: 'Щось пішло не так!',
                text: 'Будь ласка спробуйте пізніше',
            },
        },
        vote: {
            isExist: {
                title: 'Неможливо проголосувати',
                text: 'Ви вже віддали голос за компанію в цій ніші',
            },
        },

    },

    notifications: {
        registration: {
            success: {
                title: 'Успішна реєстрація',
                text: 'Ваш обліковий запис був успішно створений',
            },
        },
        login: {
            success: {
                title: 'Вхід успішний!',
                text: '',
            }
        },
        phone: {
            verificationSuccess: {
                title: 'Телефон підтверджено!',
                text: 'Тепер у вас є можливість голосувати',
            },
        },
    }
}


const i = {
    tag: 'best-partner',
    title: 'найкраща партнерська програма',
    categories: [
        {
            name: 'гемблiнг',
            tag: 'gambling',
            companies: []
        },
        {
            name: 'беттiнг',
            tag: 'betting',
            companies: []
        },
        {
            name: 'нутра',
            tag: 'nutra',
            companies: []
        },
        {
            name: 'дейтiнг',
            tag: 'dating',
            companies: []
        },
        {
            name: 'крипто',
            tag: 'crypto',
            companies: []
        },
        {
            name: 'свіпи',
            tag: 'sweepstakes',
            companies: []
        },
        {
            name: 'no-category',
            tag: 'no-category',
            companies: []
        },
    ]
}

// Найкраща партнерська програма
// Донат року
// Інфлюєнсер року
// Найкраща PR-кампанія року
// Прорив року
// Найкраща арбітражна команда
// Найкращий арбітражний сервіс (антидетек браузер, клоакінг сервіс, платіжна система, spy-сервіс)
// Колаборація року
// Роботодавець року